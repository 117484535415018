<template>
    <PageWrapper class="admin-request-management">
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <NebulaInput
                    :placeholder="$t('Enter a GUID to test its visibility in Career Connect, by employees')"
                    v-model="assetGUID"
                />
                <NebulaButton text="Click to test" @click="testGUID" />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" v-if="showResult">
            <NebulaColumn :columns="12">
                <h2>Results</h2>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" v-if="showResult" class="asset-test__results">
            <NebulaColumn :columns="4">
                <p class="asset-test__warning-note" v-if="fetched && !asset">
                    Atlas asset GUID {{ storedGUID }} is not viewable to the public
                </p>
                <ResourceCard
                    v-else-if="asset"
                    :asset="asset"
                    :loading="asset.loading"
                />
            </NebulaColumn>
            <NebulaColumn :columns="7" :offset="1">
                <div>
                    <h3>Categories</h3>
                    <div v-for="(category, idx) in Object.keys(assetContext)" :key="`category-${idx}`">
                        <h4 class="asset-test__sub-header">
                            {{ category }}{{ assetContext[category].meta ? ' (non-organization category)' : ` (${getAssetOrgId(storedGUID)})` }}
                        </h4>
                        <p class="asset-test__value">Subcategory: "{{ assetContext[category].subcategoryDisplay || "General" }}"</p>
                    </div>
                </div>
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import {
    NebulaButton,
    NebulaGrid,
    NebulaInput,
} from '@discoveryedu/nebula-components';
import createPayload from '@/mixins/data/createPayload';
import { getAssetContext, getAssetOrgId } from '@/utils/data/resources';
import ResourceCard from '@/components/shared/cards/ResourceCard.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

export default {
    name: 'AssetTest',
    mixins: [createPayload],
    components: {
        NebulaButton,
        NebulaInput,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        PageWrapper,
        ResourceCard,
    },
    data() {
        return {
            assetGUID: '',
            assetContext: {},
            fetched: false,
            showResult: false,
            storedGUID: '',
            categories: {},
        };
    },
    computed: {
        asset() {
            return this.$store.state.page.assets[this.storedGUID];
        },
    },
    methods: {
        async testGUID() {
            this.fetched = false;
            this.showResult = false;

            this.storedGUID = this.assetGUID;
            this.assetGUID = '';

            this.assetContext = getAssetContext(this.storedGUID);
            const payload = await this.createPayload({ assetId: this.storedGUID });

            this.showResult = true;
            await this.$store.dispatch('getResource', payload);

            // this.categories.orgId = getAssetOrgId(this.storedGUID);
            this.fetched = true;
        },
        getAssetOrgId,
    },
};
</script>

<style lang="stylus">
.asset-test {
    &__sub-header {
        margin-block-end: $nebula-space-half;
    }

    &__value {
        margin-block-start: 0;
    }

    &__warning-note {
        color: $nebula-color-feedback-error-500;
        font-size: 24px;
    }

    &__results {
        card-base();
        padding: $nebula-space-2x;
    }
}
</style>
