<template>
    <GrayBoxLoader class="resource-card__loader" v-if="loading" />
    <NebulaCard
        v-else
        element="li"
        class="nebula-card-carousel__list-item resource-card"
    >
        <template v-slot:thumbnail>
            <div class="resource-card__org-label">
                <img class="resource-card__org-label-de-logo" v-if="orgLabel.evergreen" :src="orgLabel.avatarUrl">
                <ProfileAvatar
                    v-else-if="orgLabel.avatarUrl"
                    class="resource-card__org-label-avatar"
                    size="xs"
                    :image="orgLabel.avatarUrl"
                    icon="buildings"
                />
                <p class="resource-card__org-label-name">{{ orgLabel.name }}</p>
            </div>
            <NebulaCardThumbnail
                class="resource-card__thumbnail"
                :assetId="asset.id"
                :title="asset.title"
                v-bind="linkSettings"
            />
        </template>
        <template v-slot:content>
            <NebulaCardHeader :assetType="assetTypeFriendly" />
            <NebulaCardTitle
                class="resource-card__title"
                tabIndex="-1"
                :title="asset.title"
                v-bind="linkSettings"
            />
            <NebulaCardDescription
                class="resource-card__description"
                :text="asset.description"
            />
            <div class="resource-card__metadata">
                <NebulaButton
                    v-for="pill in asset.info_pills"
                    :key="pill.id"
                    class="resource-card__metadata-item"
                    :link="pill.link"
                    :text="pill.name"
                    shape="pill"
                    size="s"
                />
            </div>
        </template>
    </NebulaCard>
</template>

<script>
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import { ATLAS_APP_BASE_URL, rerouteInteractives } from '@/constants';
import breakpoints from '@/mixins/breakpoints';
import formatDate from '@/mixins/formatDate';
import {
    NebulaButton,
    NebulaCard,
    NebulaCardDescription,
    NebulaCardHeader,
    NebulaCardThumbnail,
    NebulaCardTitle,
} from '@discoveryedu/nebula-components';

export default {
    name: 'ResourceCard',
    components: {
        GrayBoxLoader,
        NebulaButton,
        NebulaCard,
        NebulaCardDescription,
        NebulaCardHeader,
        NebulaCardThumbnail,
        NebulaCardTitle,
        ProfileAvatar,
    },
    mixins: [breakpoints, formatDate],
    data() {
        return {
            carouselKey: 0,

        };
    },
    props: {
        asset: Object,
        assetType: String,
        loading: Boolean,
    },
    computed: {
        assetTypeFriendly() {
            if (this.assetType) {
                return this.assetType;
            }
            if (this.asset.type && this.asset.type.name) {
                return this.asset.type.name;
            }
            return null;
        },
        orgLabel() {
            return this.asset.org;
        },
        url() {
            if (this.$store.state.app.flags['atlas-content']) {
                return `${ATLAS_APP_BASE_URL}/player/${this.asset.id}`;
            }
            if (rerouteInteractives.includes(this.asset.id) || (rerouteInteractives.includes(this.asset.pointer_id))) {
                return `http://app.discoveryeducation.com/learn/player/${this.asset.id}`;
            }
            return `/asset/${this.asset.id}`;
        },
        linkSettings() {
            if (this.$store.state.app.flags['atlas-content']) {
                return {
                    url: `${ATLAS_APP_BASE_URL}/player/${this.asset.id}`,
                };
            }
            if (rerouteInteractives.includes(this.asset.id) || (rerouteInteractives.includes(this.asset.pointer_id))) {
                return {
                    url: `http://app.discoveryeducation.com/learn/player/${this.asset.id}`,
                    target: '_blank',
                    link_aria_label: 'opens in new window',
                };
            }
            return {
                url: `/asset/${this.asset.id}`,
            };
        },
    },
};
</script>

<style lang='stylus'>
.resource-card {
    --nebula-badge-background-color: $nebula-color-platform-interactive-200;
    --nebula-badge--color: $nebula-color-platform-interactive-900;

    --nebula-card-background-color: $nebula-color-white;
    --nebula-card-border-color: unset;
    --nebula-card-border-radius: $nebula-border-radius-small;
    --nebula-card-border-width: 0;
    --nebula-card-box-shadow: $nebula-shadow-100;
    --nebula-card-content-padding: $nebula-space-3x;
    --nebula-card-header-min-height: 0;
    --nebula-card-margin: 0;
    --nebula-card-thumbnail-border-radius-top: $nebula-border-radius-small;
    --nebula-card-type-color: $nebula-color-platform-neutral-900;
    --nebula-card-thumbnail-border-radius-top: 0;

    height: 100%;

    .nebula-card__media {
        flex-direction: column;
    }

    &__loader {
        aspect-ratio: 16/9;
        border-radius: var(--nebula-card-border-radius);
    }

    &__metadata {
        display: flex;
        gap: $nebula-space-1x;
        margin-block-start: $nebula-space-1x;
    }

    &__metadata-item {
        --nebula-button-background: $nebula-color-platform-interactive-200;
        --nebula-button-background-hover: $nebula-color-platform-interactive-400;
        --nebula-button-background-active: $nebula-color-platform-interactive-500;
        --nebula-button-text-color: $nebula-color-platform-interactive-900;
        --nebula-button-text-color-hover: $nebula-color-platform-interactive-900;
        --nebula-button-text-color-active: $nebula-color-platform-interactive-1000;
    }

    &__org-label {
        background: $nebula-color-platform-interface-200;
        align-items: center;
        display: flex;
        gap: $nebula-space-1x;
        justify-content: center;
        padding: $nebula-space-1x 0;
        border-top-left-radius: $nebula-border-radius-small;
        border-top-right-radius: $nebula-border-radius-small;
    }

    &__org-label-avatar {
        --careers-avatar-size: 24px;
    }

    &__org-label-de-logo {
        width: 24px;
    }

    &__org-label-name {
        nebula-text-caption();
        font-weight: 600;
        color: $nebula-color-platform-neutral-900;
        margin: 0;
    }
}
</style>
