<template>
    <PageWrapper pageTitle="Feature Flags">
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <div v-for="(flag, idx) in Object.keys(flags)" :key="`${idx}-flag`">
                    <p>{{ flag }}: {{ flags[flag] }}</p>
                </div>
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import {
    NebulaGrid,
} from '@discoveryedu/nebula-components';
import createPayload from '@/mixins/data/createPayload';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

export default {
    name: 'AssetTest',
    mixins: [createPayload],
    components: {
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        PageWrapper,
    },
    computed: {
        flags() {
            return this.$store.getters.flags;
        },
    },
};
</script>

<style lang="stylus">

</style>
