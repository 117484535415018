<template>
    <PageWrapper>
        <NebulaGridRow v-if="show">
            <NebulaGridBlock
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="3"
                :smSize="4"
                :smOffset="0"
            >
                <div class="login">
                    <div class="login__container">
                        <div class="login__heading">
                            <h1 class="login__heading-title">{{ $t('login') }}</h1>
                            <div class="login__heading-description">
                                <p>{{ $t('login-group-select', { ns: 'login' }) }}</p>
                            </div>
                        </div>

                        <div class="card-login__container">
                            <div class="card-login__item">
                                <figure class="card-login__figure">
                                    <img class="card-login__figure-image" src="../assets/img-login-education.png"
                                         alt=""
>
                                </figure>
                                <div class="card-login__content">
                                    <p>{{ $t('educator-login-prompt', { ns: 'login' }) }}</p>
                                </div>
                                <div class="card-login__actions">
                                    <NebulaButton
                                        type="ghost"
                                        @click="handleLoginWithDE"
                                        :isDisabled="educatorLoading"
                                        :aria-label="$t('educator-login', { ns: 'login' })"
                                        :text="educatorLoginText"
                                        class="card-login__button"
                                        size="l"
                                        data-click-type="Login Selection"
                                        data-click-name="Login as Educator"
                                    />
                                </div>
                            </div>
                            <div class="card-login__item">
                                <figure class="card-login__figure">
                                    <img class="card-login__figure-image" src="../assets/img-login-company.png" alt="">
                                </figure>
                                <div class="card-login__content">
                                    <p>{{ $t('employee-login-prompt', { ns: 'login' }) }}</p>
                                </div>
                                <div class="card-login__actions">
                                    <NebulaButton
                                        type="ghost"
                                        size="l"
                                        :aria-label="$t('employee-login', { ns: 'login' })"
                                        :text="$t('employee-login', { ns: 'login' })"
                                        class="card-login__button"
                                        @click="loginAsEmployee"
                                        data-click-type="Login Selection"
                                        data-click-name="Login as Employee"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </NebulaGridBlock>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import { NebulaButton, NebulaGrid } from '@discoveryedu/nebula-components';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import { roles } from '@/constants';

export default {
    name: 'Login',
    components: {
        NebulaButton,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        PageWrapper,
    },
    data() {
        return {
            show: false,
            educatorLoading: false,
        };
    },
    computed: {
        educatorLoginText() {
            if (this.educatorLoading) {
                return this.$t('loading');
            }
            return this.$t('educator-login', { ns: 'login' });
        },
    },
    methods: {
        handleLoginWithDE() {
            this.educatorLoading = true;
            this.loginWithDE();
        },
        loginWithDE() {
            this.$de.login();
        },
        loginAsEmployee() {
            this.$auth.loginWithRedirect({
                appState: { targetUrl: '/' },
                audience: 'DE',
            });
        },
    },
    beforeMount() {
        const urlParameters = new URLSearchParams(window.location.search);
        const paramRole = urlParameters.get('role');

        if (!paramRole) {
            this.show = true;
            return;
        }

        if (roles.EDUCATOR_ROLE === paramRole.toLowerCase().trim()) {
            this.loginWithDE();
            return;
        }

        if (roles.CONNECTOR_ROLE === paramRole.toLowerCase().trim()) {
            this.loginAsEmployee();
            return;
        }

        this.show = true;
    },
};
</script>

<style lang='stylus'>
@import '../styles/login.styl';
</style>
