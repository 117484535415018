<template>
    <PageWrapper>
        <NebulaGridRow type="flex" class="admin__grid-row">
            <NebulaColumn :columns="5">
                <h1>Career Connect Admin</h1>
                <p>Note: do not refresh the page during this flow; you'll lose track of the organization you created</p>
                <ProfileFormSection header="Create a new Organization">
                    <ProfileFormField
                        name="organizationName"
                        :inputGroupData="formData.organizationName"
                        helperText="Note that users are not able to edit this organization name, only their headline and other info!"
                    />
                    <ProfileFormField
                        name="organizationUserEmail"
                        :inputGroupData="formData.organizationUserEmail"
                        placeholder="Email address"
                        helperText="This should be the email address of the first person that would log in,
                            set up the organization profile, and invite members"
                    />
                    <NebulaButton @click="createOrganization" text="Create Organization in Database" />
                </ProfileFormSection>
                <ProfileFormSection header="Add admins to an existing organization">
                    <ProfileFormField
                        name="organizationId"
                        :inputGroupData="formData.updateOrganizationId"
                        helperText="If you don't have the organization's uuid, fetch the list of organizations below"
                    />
                    <ProfileFormField
                        name="organizationUserEmail"
                        :inputGroupData="formData.newAdminEmail"
                        helperText="This should be the email address of the first person that would log in,
                            set up the organization profile, and invite members"
                    />
                    <NebulaButton @click="confirmOrgToUpdate" text="Add admin(s) to organization" />
                </ProfileFormSection>
            </NebulaColumn>
            <NebulaColumn :columns="6" :offset="1" class="admin__results">
                <h2>Results</h2>
                <div v-if="createWarning">
                    <p class="admin__warning-note">
                        {{ createWarning }}
                    </p>
                </div>
                <div v-if="createLoading">
                    Loading...
                </div>
                <div v-else>
                    <div class="admin__section" v-if="createdOrg && createdOrg.name">
                        <h3><span class="admin__happy-badge">SUCCESS</span>Organization created!</h3>
                        <div class="admin__detail-row">
                            <h3>Name of organization</h3>
                            <p>{{ createdOrg.name }}</p>
                        </div>
                        <div class="admin__detail-row">
                            <h3>Organization database ID</h3>
                            <p>{{ createdOrg.organizationId }}</p>
                            <p class="admin__happy-note">Take note of this in case you need to find this organization later</p>
                            <NebulaButton size="s" @click="copyToClipboard(createdOrg.organizationId)" icon-left="copy-to-clipboard" text="Copy" />
                        </div>
                        <div class="admin__detail-row">
                            <h3>Organization is active?</h3>
                            <p>{{ createdOrg.isActive }}</p>
                            <p class="admin__warn-note">
                                This should be FALSE for a newly created organization. New organizations
                                will not be prompted to set up profiles if this is true.
                            </p>
                        </div>
                        <div class="admin__detail-row">
                            <h3>Raw data</h3>
                            <pre>{{ createdOrg }}</pre>
                        </div>
                    </div>
                    <div v-if="createdOrgUsers && createdOrgUsers.length > 0">
                        <div class="admin__section" v-for="(user, idx) in createdOrgUsers" :key="idx">
                            <h3><span class="admin__happy-badge">SUCCESS</span>Organization USER created!</h3>
                            <div class="admin__detail-row">
                                <h3>User email</h3>
                                <p>{{ user.email }}</p>
                            </div>
                            <div class="admin__detail-row">
                                <h3>employeeId</h3>
                                <p>{{ user.employeeId }}</p>
                                <p class="admin__happy-note">Take note of this in case you need to find this user later</p>
                                <NebulaButton
                                    size="s"
                                    @click="copyToClipboard(user.employeeId)"
                                    icon-left="copy-to-clipboard" text="Copy"
                                />
                            </div>
                            <div class="admin__detail-row">
                                <h3>Raw data</h3>
                                <p>{{ user }}</p>
                            </div>
                            <div v-if="user && user.isAdmin">
                                <h3><span class="admin__happy-badge">SUCCESS</span>Organization USER is Admin!</h3>
                                <div class="admin__detail-row">
                                    <h3>User is admin?</h3>
                                    <p>{{ user.isAdmin }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="admin__section" v-if="orgToConfirm && orgToConfirm.organizationId">
                        <h3><span class="admin__happy-badge">SUCCESS</span>Organization found</h3>
                        <div class="admin__detail-row">
                            <h3>Organization name</h3>
                            <p>{{ orgToConfirm.name }}</p>
                        </div>
                        <div class="admin__detail-row">
                            <h3>Organization id</h3>
                            <p>{{ orgToConfirm.organizationId }}</p>
                        </div>
                        <NebulaButton @click="addAdminToOrg" :text="`Create ${adminToConfirm} as admin?`" />
                        <p>
                            If this is not the correct email or organization, re-enter the organization id and/or email on the left
                            and click "Add admin(s) to organization" again
                        </p>
                    </div>
                </div>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="admin__grid-row">
            <NebulaColumn :columns="5">
                <ProfileFormSection header="View an organization">
                    <ProfileFormField
                        name="organizationId"
                        :inputGroupData="formData.organizationId"
                        helperText="To check the status of an organization"
                    />
                    <div class="admin__detail-row">
                        <NebulaButton @click="getOrganization(formData.organizationId.value)" text="Fetch organization" />
                    </div>
                </ProfileFormSection>
                <ProfileFormSection header="List all organizations">
                    <div class="admin__detail-row">
                        <NebulaButton @click="listOrganizations()" text="Fetch organization list" />
                    </div>
                </ProfileFormSection>
            </NebulaColumn>
            <NebulaColumn :columns="6" :offset="1" class="admin__results">
                <h2>Data</h2>
                <div v-if="fetchWarning">
                    <p class="admin__warning-note">
                        {{ fetchWarning }}
                    </p>
                </div>
                <div v-if="fetchLoading">
                    Loading...
                </div>
                <div v-else>
                    <div v-if="fetchedEmployeeList">
                        <h3>Employee list</h3>
                        {{ fetchedEmployeeList }}
                    </div>
                    <div v-if="fetchedOrg">
                        <h3>Organization fetched!</h3>
                        <div class="admin__detail-row">
                            <h3>Name of organization</h3>
                            <p>{{ fetchedOrg.name }}</p>
                        </div>
                        <div class="admin__detail-row">
                            <h3>Organization database ID</h3>
                            <p>{{ fetchedOrg.organizationId }}</p>
                        </div>
                        <div class="admin__detail-row">
                            <h3>Organization is active?</h3>
                            <p>{{ fetchedOrg.isActive }}</p>
                        </div>
                        <div class="admin__detail-row">
                            <h3>Raw data</h3>
                            <pre>{{ fetchedOrg }}</pre>
                        </div>
                    </div>
                    <NebulaAccordion
                        text="Organization data"
                        isExpandedOnLoad
                        v-if="fetchedOrganizationList"
                    >
                        <pre>
                            {{ fetchedOrganizationList }}
                        </pre>
                    </NebulaAccordion>
                    <pre v-if="fetchedEmployeeList">
                        {{ fetchedEmployeeList }}
                    </pre>
                </div>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="admin__grid-row">
            <NebulaColumn :columns="5" class="admin__danger-zone">
                <ProfileFormSection header="Delete an organization">
                    <ProfileFormField
                        name="organizationId"
                        :inputGroupData="formData.deleteOrganizationId"
                        helperText="Find the organization to delete"
                    />
                    <div class="admin__detail-row">
                        <NebulaButton
                            @click="confirmOrgToDelete"
                            text="Find organization to delete"
                        />
                        <p>Clicking this will NOT delete the organization, you'll have one more chance to confirm!</p>
                    </div>
                </ProfileFormSection>
            </NebulaColumn>
            <NebulaColumn :columns="6" :offset="1" class="admin__results">
                <div v-if="deleteWarning">
                    <p class="admin__warning-note">
                        {{ deleteWarning }}
                    </p>
                </div>
                <div v-if="deleteLoading">
                    Loading...
                </div>
                <div v-else>
                    <div class="admin__section" v-if="orgToConfirmDelete && orgToConfirmDelete.organizationId">
                        <h3><span class="admin__happy-badge">SUCCESS</span>Organization found</h3>
                        <div class="admin__detail-row">
                            {{ orgToConfirmDelete }}
                            <h3>Organization name</h3>
                            <p>{{ orgToConfirmDelete.name }}</p>
                        </div>
                        <div class="admin__detail-row">
                            <h3>Organization id</h3>
                            <p>{{ orgToConfirmDelete.organizationId }}</p>
                        </div>
                        <NebulaButton @click="deleteOrganization" :text="`Delete ${orgToConfirmDelete.name} from database?`" />
                        <p>
                            If this is not the correct organization, re-enter the organization id on the left
                            and click "Find organization to delete" again
                        </p>
                    </div>
                </div>
                <div v-if="deleteSuccess">
                    <h3><span class="admin__happy-badge">SUCCESS</span>Organization deleted!</h3>
                </div>
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import { NebulaGrid, NebulaButton, NebulaAccordion } from '@discoveryedu/nebula-components';
import ProfileFormSection from '@/components/shared/Profile/ProfileFormSection.vue';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';

import createPayload from '@/mixins/data/createPayload';
import axios from 'axios';

const API_DOMAIN = process.env.VUE_APP_API_URL;

export default {
    name: 'OrgManagement',
    components: {
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        PageWrapper,
        ProfileFormField,
        ProfileFormSection,
        NebulaButton,
        NebulaAccordion,
    },
    mixins: [createPayload],
    data() {
        return {
            createLoading: null,
            fetchLoading: null,
            createWarning: null,
            fetchWarning: null,
            deleteLoading: null,
            deleteWarning: null,
            formData: {
                organizationName: {
                    value: '',
                    display: 'Organization Name',
                    placeholder: 'Name of the Organization',
                    error: null,
                    status: '',
                },
                organizationUserEmail: {
                    value: '',
                    display: 'Organization User\'s Email',
                    placeholder: 'Organization User\'s Email Address',
                    error: null,
                    status: '',
                },
                organizationId: {
                    value: '',
                    display: 'Organization Id',
                    placeholder: 'Organization Id',
                    error: null,
                    status: '',
                },
                updateOrganizationId: {
                    value: '',
                    display: 'Existing organization Id',
                    placeholder: 'Organization Id',
                    error: null,
                    status: '',
                },
                newAdminEmail: {
                    value: '',
                    display: 'New admin\'s email',
                    placeholder: 'Email',
                    error: null,
                    status: '',
                },
                schoolName: {
                    value: '',
                    display: 'School Name',
                    placeholder: 'Name of the School',
                    error: null,
                    status: '',
                },
                schoolUserEmail: {
                    value: '',
                    display: 'School User\'s Email',
                    placeholder: 'School User\'s Email Address',
                    error: null,
                    status: '',
                },
                schoolId: {
                    value: '',
                    display: 'School Id',
                    placeholder: 'School Id',
                    error: null,
                    status: '',
                },
                deleteOrganizationId: {
                    value: '',
                    display: 'Organization Id',
                    placeholder: 'Organization Id',
                    error: null,
                    status: '',
                },
            },
            createdOrg: null,
            createdOrgUsers: [],
            createdUser: null,
            createdSchool: null,
            createdSchoolUser: null,
            fetchedOrg: null,
            fetchedEmployeeList: null,
            orgToConfirm: null,
            orgToConfirmDelete: null,
            adminToConfirm: null,
            fetchedOrganizationList: null,
            loadingCreateOrg: false,
            deleteSuccess: null,
        };
    },
    methods: {
        async createOrganization() {
            this.createWarning = false;
            if (!this.formData.organizationName.value || this.formData.organizationName.value.length < 1) {
                this.createWarning = 'Organization Name required to create an organization';
                return;
            }
            if (!this.formData.organizationUserEmail.value || this.formData.organizationUserEmail.value.length < 1) {
                this.createWarning = 'Email required. A user will be need to be created for the new organization.';
                return;
            }

            // pull out list of emails
            const emails = this.formData.organizationUserEmail.value.split(',').map((each) => each.trim().toLowerCase());
            const invalidEmails = emails.filter((eachEmail) => !this.validateEmail(eachEmail));
            if (invalidEmails.length > 0) {
                this.createWarning = 'Invalid email detected, please check your input. An invalid email cannot receive an invitation.';
                return;
            }
            this.createLoading = true;
            let organizationId;
            const payload = await this.createPayload({ name: this.formData.organizationName.value });

            // Step 1: create the organization
            try {
                const response = await axios.post(`${API_DOMAIN}/organizations`,
                    payload.data,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                if (response.status === 200) {
                    this.createdOrg = response.data;
                }
                organizationId = response.data.organizationId;
                this.createWarning = false;
            } catch (error) {
                this.createLoading = false;
                this.createWarning = `Organization creation failed with error ${error}, cannot create user if organization creation failed.`;
            }

            if (!organizationId) {
                this.createLoading = false;
                return;
            }

            // Step 2: create the user
            await this.createOrgUser(organizationId, emails);
            if (!this.createdOrgUsers) {
                this.createLoading = false;
                return;
            }

            // Step 3: promote the user
            await this.promoteUserToAdmin(organizationId, this.createdOrgUsers);

            this.createLoading = false;
            this.formData.organizationName.value = '';
            this.formData.organizationUserEmail.value = '';
        },
        async createOrgUser(organizationId, emails) {
            const emailsToAdd = emails;
            const payload = await this.createPayload();
            try {
                const response = await axios.post(`${API_DOMAIN}/organizations/${organizationId}/employees`,
                    emailsToAdd,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                if (response.status === 200) {
                    // this returns an array of all employees. Find the right record
                    this.createdOrgUsers = response.data.records.filter((user) => emailsToAdd.includes(user.email));
                }
                this.createWarning = false;
            } catch (error) {
                this.createLoading = false;
                this.createWarning = `Organization USER creation failed with error ${error}`;
            }
            this.createLoading = false;
        },
        async promoteUserToAdmin(organizationId, orgUsers) {
            this.createLoading = true;

            if (!orgUsers) {
                return;
            }
            // promote each user email to admin
            await orgUsers.forEach(async (orgUser) => {
                const { email, employeeId } = orgUser;
                const payload = await this.createPayload({
                    email,
                    isAdmin: true,
                });

                try {
                    const response = await axios.post(`${API_DOMAIN}/organizations/${organizationId}/employees/${employeeId}`,
                        payload.data,
                        {
                            headers: {
                                Authorization: `Bearer ${payload.token}`,
                            },
                        });
                    if (response.status === 200) {
                        const createdOrgUser = response.data.find((user) => user.email === email);
                        if (!createdOrgUser.isAdmin) {
                            this.createWarning = 'User promotion request did not error, but user isAdmin is false';
                        } else {
                            let indexToUpdate;
                            this.createdOrgUsers.find((user, idx) => {
                                if (user.email === email) {
                                    indexToUpdate = idx;
                                    return true;
                                }
                                return false;
                            });
                            this.createdOrgUsers.splice(indexToUpdate, 1, createdOrgUser);
                        }
                    }
                    this.createWarning = false;
                } catch (error) {
                    this.createWarning = `Promote org user to admin failed with error ${error}`;
                    this.createLoading = false;
                }
            });
            this.orgToConfirm = null;
            this.createLoading = false;
        },
        async getEmployeeList(organizationId) {
            this.fetchLoading = true;

            await this.getOrganization();
            const payload = await this.createPayload();

            try {
                const response = await axios.get(`${API_DOMAIN}/organizations/${organizationId}/employees`,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                if (response.status === 200) {
                    this.fetchedEmployeeList = response.data;
                }
                this.fetchWarning = false;
            } catch (error) {
                this.fetchWarning = `Get employee list failed with error ${error}`;
            }
            this.fetchLoading = false;
        },
        async getOrganization(
            organizationId = this.formData.organizationId.value,
            fetch = true,
            storeKey = 'fetchedOrg',
            warningKey = 'fetchWarning',
        ) {
            const orgIdWarning = 'Organization ID required to fetch an organization\'s data';

            // if fetch is set to false, this is a confirmation step. Warn and validate accordingly
            if (fetch) {
                this.orgToConfirm = null;
            }
            if (!organizationId || organizationId.length < 1) {
                this[warningKey] = orgIdWarning;
                return;
            }

            this.fetchLoading = true;
            const payload = await this.createPayload();

            try {
                const response = await axios.get(`${API_DOMAIN}/organizations/${organizationId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                if (response.status === 200) {
                    this[storeKey] = response.data;
                    this[warningKey] = false;
                }
            } catch (error) {
                this[warningKey] = `Get organization failed with error ${error}`;
            }
            this.fetchedOrganizationList = null;
            this.fetchLoading = false;
        },
        async confirmOrgToUpdate() {
            this.createLoading = true;

            // reset results display
            this.createdOrg = null;
            this.createdOrgUsers = [];
            if (!this.formData.newAdminEmail.value || !this.formData.newAdminEmail.value.length > 0) {
                this.createWarning = 'New admin\'s email required to create new organization admin';
                return;
            }
            if (!this.validateEmail(this.formData.newAdminEmail.value)) {
                this.createWarning = 'Invalid email detected, please check your input. An invalid email cannot receive an invitation.';
                this.createLoading = true;
                return;
            }
            // fetch and display organization to confirm
            await this.getOrganization(this.formData.updateOrganizationId.value, false, 'orgToConfirm', 'createWarning');

            if (this.orgToConfirm.organizationId) {
                this.createLoading = false;
                this.adminToConfirm = this.formData.newAdminEmail.value;
                this.formData.newAdminEmail.value = null;
                this.createWarning = 'Please confirm the organization and email are correct, then hit the "Create [email] as admin button below"';
            }
        },
        async confirmOrgToDelete() {
            this.deleteLoading = true;
            this.deleteSuccess = null;
            this.orgToConfirmDelete = null;

            await this.getOrganization(
                this.formData.deleteOrganizationId.value,
                false,
                'orgToConfirmDelete',
                'deleteWarning',
            );
            this.deleteLoading = false;

            if (this.orgToConfirmDelete && this.orgToConfirmDelete.organizationId) {
                await this.getEmployeeList(this.orgToConfirmDelete.organizationId);

                this.formData.deleteOrganizationId.value = null;
                this.deleteWarning = 'Please confirm the organization is correct, then delete if so';
            } else {
                this.deleteWarning = 'Organization not found';
            }
        },
        async deleteOrganization() {
            const orgId = this.orgToConfirmDelete.organizationId;
            const payload = await this.createPayload();
            try {
                const response = await axios.delete(`${API_DOMAIN}/organizations/${orgId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                if (response.status === 204) {
                    this.deleteSuccess = true;
                }
            } catch (error) {
                console.log(payload);
                console.log(error);
            }
        },
        async addAdminToOrg() {
            this.createLoading = true;
            const emailToAdd = this.adminToConfirm.trim().toLowerCase();
            const { organizationId } = this.orgToConfirm;
            // Step 1: create the user
            // returns an array, but we're enforcing one at a time for this option
            const orgUser = await this.createOrgUser(organizationId, [emailToAdd]);
            if (!orgUser) {
                this.createLoading = false;
            }

            // Step 2: promote the user to admin
            await this.promoteUserToAdmin(organizationId, this.createdOrgUsers);
            this.createLoading = false;
        },
        async listOrganizations() {
            this.fetchLoading = true;
            const payload = await this.createPayload();

            try {
                const response = await axios.get(`${API_DOMAIN}/organizations`,
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`,
                        },
                    });
                if (response.status === 200) {
                    this.fetchedOrganizationList = response.data;
                    this.fetchWarning = false;
                }
            } catch (error) {
                this.fetchWarning = `Get organization list failed with error ${error}`;
            }
            this.fetchLoading = false;
        },
        validateEmail(string) {
            const regex = '^[A-Za-z0-9+\'._%$-]+@[A-Za-z0-9.-]+[.][A-Za-z]+$';
            return string.match(regex);
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);

            this.update = `"${text}" copied to clipboard`;
        },
    },
};
</script>

<style lang="stylus">
.admin {
    &__detail-row {
        h3, p {
            margin: 0;
        }

        margin-bottom: $nebula-space-3x;
    }
    &__warning-note {
        color: $nebula-color-feedback-error-500;
        font-size: 24px;
    }
    &__happy-note {
        color: $nebula-color-feedback-success-600;
        font-weight: 700;
    }
    &__warn-note {
        color: $nebula-color-feedback-error-500;
        font-weight: 700;
    }
    &__happy-badge {
        color: $nebula-color-feedback-success-600;
        font-weight: 700;
        padding-right: $nebula-space-1x;
    }
    &__results {
        card-base();
        padding: $nebula-space-2x;
    }
    &__grid-row {
        border-bottom: 1px solid $nebula-color-platform-neutral-300;
        margin-bottom: $nebula-space-2x;
        padding-bottom: $nebula-space-4x;
    }
    &__section {
        padding-bottom: $nebula-space-2x;
        border-bottom: 1px solid $nebula-color-platform-neutral-600;
    }
    &__danger-zone {
        background-color: $nebula-color-feedback-error-100;
        border: 1px solid $nebula-color-feedback-error-400;
        padding: 0 $nebula-space-2x;
    }
}
</style>
